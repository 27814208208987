import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import imgDefault from '../../assets/image/placeholder.png'
import { FaCheck, FaCheckDouble } from "react-icons/fa";


const Block_10 = ({sloganPrincipal, title, text, listsAbout, listsServices, image }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <section className="w-full flex justify-center md:py-32 py-10">
                <div className="max-w-7xl md:flex md:flex-row-reverse justify-center md:p-0 px-2 content-reverse">
                    <div className="md:w-[40%] ">
                        <div className="w-full h-full block relative">
                            <div className="w-full h-[360px] lg:h-[60%] bg-cover bg-center rounded-2xl"
                                style={{ backgroundImage: `url("${image ? image : imgDefault}")` }}>
                            </div>
                            <img src={rpdata?.stock?.[17]} alt="fondo" className="w-[300px] h-auto md:absolute md:block hidden md:bottom-[40%] -right-4 border-white border-[15px] rounded-lg" />
                        </div>
                    </div>

                    <div className="md:w-[50%] py-4 md:px-6 px-3 self-center md:text-start text-center">
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h2 className='pb-3 capitalize md:text-start text-center'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        <div className="flex md:flex-row flex-col justify-center items-center py-3">
                            <div className="md:w-[50%] w-full h-auto">
                                <img src={rpdata?.stock?.[0]} alt="img" className="md:w-[90%] w-full h-auto rounded-md" />

                            </div>
                            <div className=" w-[60%]">
                                {
                                    listsAbout ?
                                        <ul className='grid pb-5'>
                                            {
                                                rpdata?.dbAbout?.[0].list.length > 1 ?
                                                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                        return (

                                                            <li key={index} className="py-2 flex items-center">
                                                                <FaCheckDouble />
                                                                <span className="pl-2">{item}</span>
                                                            </li>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </ul>
                                        : null
                                }
                                {
                                    listsServices ?
                                        <ul className="block pb-5">
                                            {rpdata?.dbServices?.slice(0, 5).map((item, index) => {
                                                return (
                                                    <li key={index} className="py-1 flex items-center">
                                                        <FaCheck className="mx-2 text-orange-700" />
                                                        <span>{item.name}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        : null
                                }
                            </div>

                        </div>
                        <div className="flex justify-end px-20">
                            <ButtonContent />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Block_10;