import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "react-tabs/style/react-tabs.css";




const Map = () => {

  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="md:w-[95%] flex md:flex-row flex-col mx-auto justify-center mt-[60px]">
        {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        }

      </div>

      {/* {
        rpdata?.dbPrincipal?.location.length > 1 ?

          <Tabs>
            <TabList>
              <div className="flex flex-col md:flex-row justify-center text-center">
                {
                  rpdata?.dbPrincipal?.location?.map((item, index) => {
                    return <Tab key={index}>{item.address}</Tab>;
                  })
                }
              </div>
            </TabList>
            {
              rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <TabPanel key={index}>
                    <iframe
                      loading="lazy"
                      title="Cover Locations"
                      src={item.url}
                      style={{ width: "100%", height: "400px" }}
                    />
                  </TabPanel>
                )
              })
            }
          </Tabs>
          : */}
      <div className="w-full h-auto flex md:flex-row flex-col gap-5">
        <iframe
          src={rpdata?.dbPrincipal?.location?.[0].url}
          loading="lazy"
          title="Cover Locations"
          className="md:w-1/2 w-full h-[500px]"
        />

        <iframe
          src={rpdata?.dbPrincipal?.location?.[1].url}
          loading="lazy"
          title="Cover Locations"
          className="md:w-1/2 w-full h-[500px]"
        />
      </div>


      {/* } */}

    </div>
  );
};
export default Map;